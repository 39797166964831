$image-container-size: 144px;
$wide-image-container-size: 254px;

.uploader {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  &.singleUpload {
    gap: 0;
  }

  &.wide {
    .imageContainer, .overlay:before {
      width: $wide-image-container-size !important;
    }

    .upload {
      & div {
        width: $wide-image-container-size !important;
      }
    }
  }

  .imageContainer {
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    height: $image-container-size;
    width: $image-container-size;
  }
  .overlay {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.45);
      width: 100%; // $image-container-size;
      height: 100%; // $image-container-size;
      z-index: 1;
      transition: all 0.3s;
      content: " ";
      opacity: 0;
    }

    .contentInOverlay {
      display: flex;
      gap: 6px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      opacity: 0;
      transition: all 0.3s;

      color: rgba(255, 255, 255, 0.65);
      font-size: 16px;
      padding: 2px;

      .btn {
        transition: all 0.2s;
        padding: 1px;
        border-radius: 4px;

        &:hover {
          color: white;
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    &:hover {
      &:before, .contentInOverlay {
        opacity: 1;
      }
    }
  }

  .upload {
    width: auto !important;

    & div {
      width: $image-container-size !important;
      height: $image-container-size !important;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }

  .uploadedImg {
    height: 100%;
    width: 100%;
    position: relative;

    object-fit: contain;
  }
}

.uploadBtn {
  height: $image-container-size;
  width: $image-container-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hiddenUpload {
  display: none;
}

.previewModalImage {
  margin-top: 24px;
  max-height: 80vh;
  min-height: 200px;
  background-color: rgba(211, 211, 211, 0.5);
}
