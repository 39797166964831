@import 'src/resource/style/variables';

.layout {
  max-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: $bg-color-content;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    padding: 0 8px;

    .logoSvg {
      width: 100%;
      max-width: 162px;
      height: 32px;
      object-fit: contain;
    }

    &.collapsed {
      .logoSvg {
        height: 28px;
      }
    }
  }

  .filter {
    height: calc(100% - 64px);
    overflow: hidden;

    &.collapsed {
      display: none;
    }
  }

  .slider {
    position: relative;
    z-index: 3;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    background-color: #fff;
    box-shadow: inset -1px 0px 0px #edf0f3;

    &.absolute {
      position: absolute;
    }

    .btn {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      z-index: 10;
      width: 36px;
      height: 36px;
      background: #ffffff;
      border-radius: 0;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
      transition: 0.1s;

      &.collapsed {
        top: 72px;
        right: 6px;
        box-shadow: none;
      }
    }
  }
}

.header {
  padding: 0;

  &.headerLogo {
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: auto 1fr;

    .logo {
      width: 224px;
      border-right: solid 1px #edf0f3;

      &.tablet {
        width: 175px;
      }
    }
  }
}

.wrapper {
  position: relative;
  background-color: $bg-color-content;

  &.tablet {
    margin-left: 48px;
  }

  .scroll {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: calc(100vh - 64px);
    overflow: auto;

    .content {
      position: relative;
      box-sizing: border-box;
      min-width: calc(100% - 16px * 2);
      min-height: calc(100vh - 64px - 16px * 2);
      margin: 16px;
      background: #ffffff;
      border-radius: 8px;

      &.transparent {
        background: transparent;
      }

      &.minWidth {
        min-width: 920px;
      }
    }
  }
}
